.message-icon {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 1000;
  background-color: #007bff;
  padding: 10px;
  border-radius: 50%;
  color: white;
}

.chatlist-container {
  flex: 0 0 20%;
  height: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.chatlist-container.visible {
  display: block;
}

.chat-list-header {
  padding: 15px;
  border-bottom: 1px solid #eee;
  background-color: #f8f9fa;
}

.chat-list-header h3 {
  margin: 0;
  color: #333;
}

.chat-list-content {
  padding: 15px;
  height: calc(100% - 60px);
  overflow-y: auto;
  /*
  flex-direction: column-reverse;
  display: flex;
*/
}

.chat-item {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.question {
  margin-bottom: 10px;
  color: #2c3e50;
}

.answer {
  color: #34495e;
  padding-left: 10px;
  border-left: 3px solid #007bff;
  padding-top: 10px;
  background-color: #ffffff;
}

.markdown-content {
  margin-top: 8px;
  line-height: 1.6;
}

/* 마크다운 스타일링 */
.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 16px;
  margin-bottom: 8px;
  color: #2c3e50;
}

.markdown-content p {
  margin: 8px 0;
}

.markdown-content ul,
.markdown-content ol {
  padding-left: 20px;
  margin: 8px 0;
}

.markdown-content code {
  background-color: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
}

.markdown-content pre {
  background-color: #f0f0f0;
  padding: 12px;
  border-radius: 4px;
  overflow-x: auto;
}

.markdown-content blockquote {
  border-left: 4px solid #007bff;
  margin: 8px 0;
  padding-left: 16px;
  color: #666;
}

.no-messages {
  text-align: center;
  color: #666;
  padding: 20px;
}

.loading {
  text-align: center;
  color: #666;
  padding: 10px;
  font-style: italic;
}

.collapse-button {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #eee;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.collapse-button:hover {
  background-color: #f8f9fa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.collapse-button svg {
  color: #666;
  font-size: 24px;
}
