/* Sidebar 스타일 */
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  background-color: #F9FAFB;
  color: #333;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  /*
  padding: 10px;
  */
  transition: all 0.3s ease;
}

/* TopBar 스타일 */
.top-bar {
  height: 60px;
  background-color: #fff;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /*
padding: 0 20px;
  */
}

.top-bar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* 로그아웃 버튼 스타일 */
.logout-button {
  background-color: #FF6B6B;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #ff4c4c;
}

/* 검색 바 스타일 */
.search-bar {
  padding: 10px 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.search-bar input {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s ease;
}

.search-bar input:focus {
  border-color: #ff6b6b;
  outline: none;
}

/* 검색 버튼 스타일 */
.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.search-button svg {
  stroke: #000; /* SVG 아이콘을 검정색으로 설정 */
}

.search-button:hover svg {
  stroke: #555; /* Hover 시 약간 밝은 검정색으로 */
}

/* 환자 리스트 스타일 */
.null {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.patient-list {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

.patient-list::-webkit-scrollbar {
  width: 6px;
}

.patient-list::-webkit-scrollbar-track {
  background: transparent;
}

.patient-list::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 3px;
}

.patient-list ul {
  flex: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}

.patient-item {
  display: flex;
  background-color: #fff;
  color: #333;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.patient-item:hover {
  background-color: #f5f5f5;
}

.patient-info-container {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.patient-info-row {
  display: flex;
  align-items: center;
  min-height: 24px;
}

.patient-basic-info {
  flex: 1;
  min-width: 0;
}

.patient-basic-info p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bookmark-section {
  display: flex;
  padding-left: 12px;
  margin: -10px -10px -10px 0;
  align-items: center;
  justify-content: center;
}

.bookmark-container {
  position: relative;
  display: flex;
  align-items: center;
}

.bookmark-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
}

.bookmark-button svg {
  width: 32px;
  height: 32px;
}

.patient-account {
  font-size: 12px;
  color: #888;
  margin: 0;
}

.bookmark-button.void {
  color: black;
}

.bookmark-button.void svg {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.5;
}

.bookmark-button.green {
  color: #4CAF50;
}

.bookmark-button.blue {
  color: #2196F3;
}

.bookmark-button.red {
  color: #f44336;
}

.bookmark-color-menu {
  position: absolute;
  top: -5px;
  right: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 9999;
  padding: 4px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.color-option {
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  margin: 2px 0;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.color-option:hover {
  background-color: #f5f5f5;
}

.color-option.void {
  color: #000000;
}

.color-option.void svg {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.5;
}

.color-option.green {
  color: #4CAF50;
}

.color-option.blue {
  color: #2196F3;
}

.color-option.red {
  color: #f44336;
}

.filter-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.filter-button {
  width: 40px;
  height: 40px;
  border: 2px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.2s ease;
}

.filter-button.all {
  background-color: white;
  border-color: #666;
  color: #666;
}

.filter-button.red {
  background-color: #f44336;
  border-color: #f44336;
}

.filter-button.blue {
  background-color: #2196F3;
  border-color: #2196F3;
}

.filter-button.green {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

.filter-button.void {
  background-color: white;
  border-color: black;
  color: white;
}

.filter-button.active {
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  transform: scale(0.95);
}

.filter-button:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

.filter-button.active:hover {
  transform: scale(0.95);
}
