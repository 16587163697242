/* 전체 대시보드 레이아웃 */

:root {
  --scale-factor: 1;
  --base-font-size: 26px; /* 기본 폰트 크기 */
}

.dashboard {
  display: grid;
  grid-template-columns: 250px 1fr 1fr;
  grid-template-rows: minmax(50px, 10%) minmax(200px, 45%) minmax(200px, 45%);
  grid-gap: 10px;
  height: 100vh;
  box-sizing: border-box;
  padding: 10px;
  transform-origin: top left;
  transform: scale(var(--scale-factor));
  width: calc(100% / var(--scale-factor));
  height: calc(100vh / var(--scale-factor));
  font-size: calc(var(--base-font-size) * var(--scale-factor));
}

/* 노트북 화면 (1366px 이하) */
@media screen and (max-width: 1920px) {
  :root {
    --scale-factor: 0.75;
  }
  .dashboard-container {
    width: calc(100% / var(--scale-factor));
  }
}

/* 노트북 화면 (1366px 이하) */
@media screen and (max-width: 1366px) {
  :root {
    --scale-factor: 0.7;
  }
  .dashboard-container {
    width: calc(100% / var(--scale-factor));
  }
}

/* 작은 노트북 화면 (1024px 이하) */
@media screen and (max-width: 1023px) {
  :root {
    --scale-factor: 0.6;
  }
  .dashboard-container {
    width: calc(100% / var(--scale-factor));
  }
}

/* 태블릿 화면 (768px 이하) */
@media screen and (max-width: 768px) {
  :root {
    --scale-factor: 0.65;
  }
  .dashboard-container {
    width: calc(100% / var(--scale-factor));
  }
}

/* 브라우저의 실제 가용 높이를 고려하여 미디어 쿼리 조건을 수정 */
@media screen and (max-height: 768px) {
  :root {
    --scale-factor: 0.75;
  }
  .dashboard-container {
    width: calc(100% / var(--scale-factor));
  }
}


/* 태블릿 화면 */
@media screen and (max-height: 500px) {
  :root {
    --scale-factor: 0.65;
  }
  .dashboard-container {
    width: calc(100% / var(--scale-factor));
  }
}

/* 사이드바 */
.sidebar {
  grid-row: 1 / span 3;
  grid-column: 1 / 2;
  background-color: #f9fafb;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

/* patient-info */
.patientinfo {
  grid-row: 1 / 2;
  grid-column: 2 / 4;
  background-color: #fff;
  padding: 10px;
  height: auto;
  display: flex;
  align-items: center;
}

/* patientgrid */
.patientgrid {
  grid-row: 2 / 3;
  grid-column: 2 / 4;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 95%; /* 높이를 약간 줄여 하단과 여백 확보 */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: calc(var(--base-font-size) * var(--scale-factor));
  
}

.patientgrid-content {
  flex: 1;
  overflow-y: auto;
}

/* devicestatusform */
.devicestatus {
  grid-row: 3 / 4;
  grid-column: 2 / 4;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* diet 
.diet {
  grid-row: 3 / 4;
  grid-column: 3 / 4;
  background-color: #fff;
  padding: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%; 
  overflow-y: auto; 
*/

/* Flexbox로 외부 컴포넌트 배치 */
.dashboard-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}

.grid-container {
  flex: 0 0 80%;
}

.stats-container, .chatlist-container.visible, .chatlist-container {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stats-container.hidden {
  display: none;
}

.pulse, .weight, .bloodpressure {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  flex: 1;
  height: 32%;
  padding: 10px;
}

.pulse p, .weight p, .bloodpressure p,
.pulse h3, .weight h3, .bloodpressure h3 {
  margin: 4px
}


.tab-content {
  max-height: 100%; /* 부모의 최대 높이를 사용 */
  /*
  overflow-y: auto;
  */
}

.chartcontainer {
  height: 31vh;
  width: 100%;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chartcontainer h3, .chartcontainer p, .chartcontainer h2, .chartcontainer h1 {
  margin: 5px;
}

.side-tab-container {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*
  height: calc(100vh - 40px);
  */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
}

.hidden {
  display: none;
}