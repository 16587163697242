/* Header */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 20px;
  border-bottom: 1px solid #555;
}

.logged-in-user-info {
  text-align: center;
}

.logged-in-user-info p {
  margin: 10px 0;
  font-size: 18px;
}

.auth-buttons button {
  background-color: #fff;
  color: #333;
  border: 1px solid #555;
  padding: 10px 20px;
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.auth-buttons button:hover {
  background-color: #333;
  color: #fff;
  border: 1px solid #fff;
}

/* Modal Style */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  margin: auto;
  border: 1px solid #ddd;
}

h2 {
  text-align: center;
  color: #333;
}

form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

form button {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

form button:hover {
  background-color: #555;
}

button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}
/*
button:hover {
  background-color: #555;
}
*/
/* Close and Toggle Buttons */
button:disabled {
  background-color: #888;
  cursor: not-allowed;
}
