.side-tab {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab-buttons {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
}

.tab-button {
  flex: 1;
  padding: 12px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  transition: all 0.3s ease;
}

.tab-button:hover {
  background-color: #e8e8e8;
}

.tab-button.active {
  color: #2196f3;
  border-bottom: 2px solid #2196f3;
  background-color: #fff;
}

.tab-content {
  flex: 1;
  /*
  overflow-y: auto;
*/
}

.stats-container,
.notification-container,
.chatlist-container {
  height: 100%;
}

.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  color: #666;
  font-size: 16px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 20px 0;
}

.loading-message p {
  margin: 0;
  padding: 20px;
}
